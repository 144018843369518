import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./App/Pages/Homepage";
import Login from "./Auth/Pages/Login";
import Category from "./Product/Pages/Category";
import ClientRoutes from "./common/ClientRoutes";
import css from "./App.css";
import NotFound from "./App/Pages/NotFound";
import Product from "./Product/Pages/Product";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cart from "./Cart/Pages/Cart";
// import CartContext from "./Cart/State/CartContext";
import CartProvider from "./Cart/State/CartProvider";
import Navbar from "./App/Components/Navbar";
import Order from "./Cart/Pages/Order";
import OrderDetail from "./Cart/Pages/OrderDetail";
import Signup from "./Auth/Pages/Signup";
import Footer from "./App/Components/Footer";
function App() {
  return (
    <Router>
      <CartProvider>
        <Navbar />
        <Routes>
          <Route path={ClientRoutes.Routes.home_route} element={<Homepage />} />

          <Route
            path={ClientRoutes.UserRoutes.login_route}
            element={<Login />}
          />

          <Route
            path={ClientRoutes.UserRoutes.register_route}
            element={<Signup />}
          />

          <Route
            path={`${ClientRoutes.CategoryRoutes.base_route}/:category_id`}
            element={<Category />}
          />

          <Route
            path={`${ClientRoutes.ProductRoutes.base_route}/:product_id`}
            element={<Product />}
          />
          <Route
            path={`${ClientRoutes.CartRoutes.base_route}`}
            element={<Cart />}
          />

          <Route
            path={`${ClientRoutes.OrderRoutes.base_route}`}
            element={<Order />}
          />

          <Route
            path={`${ClientRoutes.OrderRoutes.base_route}/:order_id`}
            element={<OrderDetail />}
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <Footer /> */}
        <ToastContainer position="bottom-right" />
      </CartProvider>
    </Router>
  );
}

export default App;
