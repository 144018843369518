import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import routes from "../../common/ServerRoutes";
import bg from "../static/images/categroy-img.png";
import ProductCard from "../Components/ProductCard";
import Responses from "../../common/Responses";
import ClientRoutes from "../../common/ClientRoutes";
import ServerRoutes from "../../common/ServerRoutes";
import Footer from "../../App/Components/Footer";
const Category = () => {
  const { category_id } = useParams();
  const [category, setCategory] = useState({});
  const [products, setProducts] = useState([]);
  const [bg, setBg] = useState("");
  useEffect(() => {
    axios
      .get(routes.CategoryRoutes.products_by_category_route(category_id))
      .then((res) => {
        setCategory(res.data.data.category);
        setProducts(res.data.data.products);
      })
      .catch((err) => {
        console.log(err.response);
        window.location.href = ClientRoutes.Routes.NOT_FOUND_404;
      });
  }, [category_id]);

  useEffect(() => {
    if (category.image !== undefined)
      setBg(ServerRoutes.BaseRoute.base_route + category.image.substring(1));
  }, [category.image]);
  return (
    <div className="pb-44">
      <div className="h-screen md:h-[50vh] mt-18 relative">
        <img
          src={bg}
          alt=""
          className="w-full h-full md:h-full object-cover z-[-1]"
        />
        <div
          className="absolute top-0 left-0 bg-black 
          w-full h-full md:h-[50vh] opacity-60 z-[30]
          flex justify-center items-center flex-col text-center
          gap-y-4 p-8 md:p-12
          "
        >
          <h1 className="text-white text-3xl font-semibold item-center">
            {category.title}
          </h1>
          <p
            className="text-white lg:max-w-[75%]"
            dangerouslySetInnerHTML={{ __html: category.description }}
          ></p>
        </div>
      </div>
      {products.length > 0 ? (
        <div className="category-container mt-6 w-full p-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-x-4 gap-y-8 items-center">
          {products.map((product) => (
            <>
              <ProductCard product={product} />
            </>
          ))}
        </div>
      ) : (
        <h1 className="w-full text-center py-12 text-2xl">
          No Products Available.
        </h1>
      )}
        <Footer/>
    </div>
  );
};

export default Category;
