import axios from "axios";
import PaymentSettings from "../common/PaymentRoutes";
import api from "../common/hooks/api";
import KhaltiCheckout from "khalti-checkout-web";
import ServerRoutes from "../common/ServerRoutes";

const initiatePayment = async (
  return_url,
  website_url,
  amount,
  purchase_order_id,
  purchase_order_name
) => {
  return await axios
    .post(
      PaymentSettings.KhaltiRoutes.intiate_payment_route,
      {
        return_url,
        website_url,
        amount,
        purchase_order_id,
        purchase_order_name,
      },

      {
        headers: {
          Authorization: process.env.REACT_APP_KHALTI_PUBLIC_KEY,
        },
      }
    )
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err.message);
    });
};

const khalti_V1_checkout = (
  product_id,
  product_name,
  product_url,
  on_success,
  on_error
) => {
  const config = PaymentSettings.get_khalti_config(
    product_id,
    product_name,
    product_url,
    on_success,
    on_error
  );
  return new KhaltiCheckout(config);
};

const khalti_V2_checkout = ({
  return_url,
  price,
  purchase_order_id,
  purchase_order_name,
  access_token,
}) => {
  return api
    .post(
      ServerRoutes.PaymentRoutes.payment_initiate_route,
      {
        return_url: return_url,
        price: price,
        purchase_order_id: purchase_order_id,
        purchase_order_name: purchase_order_name,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((response) => {
      if (response.status === 200 && response.data.payment_url) {
        window.location.href = response.data.payment_url;
      }
    });
};

export default {
  initiatePayment,
  khalti_V1_checkout,
  khalti_V2_checkout,
};
