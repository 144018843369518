import { useState, useEffect, useContext } from "react";
import useRequest from "../../common/hooks/useRequest";
import ServerRoutes from "../../common/ServerRoutes";
import Requests from "../../common/Requests";
import CartRow from "../Components/CartRow";
import CartHelper from "../helpers";
import ClientRoutes from "../../common/ClientRoutes";
import cartContext from "../State/CartContext";
import ProductServices from "../../Product/services";
import api from "../../common/hooks/api";
import axios from "axios";
const Cart = () => {
  const [total, setTotal] = useState(0);
  let tot = 0; // Variable to store the value of total independent of the component re-renders.
  // Using `useMemo` hook can potentially remove the need to use this variable...

  const cart = useContext(cartContext);

  useEffect(() => {
    ProductServices.fetch_cart(localStorage.getItem("access"))
      .then((response) => {
        cart.setCart(response.data.data);
        cart.setCartProducts(response.data.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cart.setCart, cart.setCartProducts, cart.refresh_value]);

  useEffect(() => {
    let tot = 0;
    if (cart.cart_products.length > 0) {
      cart.cart_products.forEach((product) => {
        tot += product.product.price * product.quantity;
      });
      cart.setCartTotal(tot);
    }
  }, [cart.cart_products, cart.setCartTotal, cart.refresh_value]);

  const handleCheckout = () => {
    // const checkout = CartHelper.khalti_V1_checkout(
    //   cart.cart.id,
    //   cart.cart.id.toString(),
    //   "https://kritizkitchen.com/cart",
    //   (data) => {
    //     api
    //       .put(
    //         ServerRoutes.OrderRoutes.base_route,
    //         {
    //           status: "ORDERED",
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem("access")}`,
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         const temp_id = cart.cart.id;
    //         cart.setCart({});
    //         cart.setCartProducts([]);
    //         cart.setCartTotal(0);
    //         window.location.href = ClientRoutes.OrderRoutes.order_detail_route(
    //           temp_id
    //         );
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //       });
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    // checkout.show({ amount: cart.cart_total * 100 });
    // console.log(cart.cart_products)

    CartHelper.khalti_V2_checkout({
      return_url: ServerRoutes.PaymentRoutes.cart_order_route,
      price: cart.cart_total * 100,
      purchase_order_id: cart.cart.id,
      purchase_order_name: "cart",
      access_token: localStorage.getItem("access"),
    });
  };
  if (cart.cart_products.length === 0) {
    return (
      <div className="p-8 mt-24">
        <h1 className="text-3xl text-center">Cart</h1>
        <h1 className="text-center text-lg">Your cart is empty</h1>
      </div>
    );
  }

  return (
    <div className="p-8 mt-24">
      <h1 className="text-3xl text-center">Cart</h1>
      <div className="w-full overflow-auto p-1">
        <table className="table-auto w-full mt-8 gap-9">
          <thead className="text-xl">
            <tr>
              <th className="px-4">Product</th>
              <th className="px-4">Qty</th>
              <th className="px-4">Price</th>
              <th className="px-4">Total</th>
              <th className="px-12 md-px-0"> </th>
            </tr>
          </thead>
          {cart.cart_products.map((product) => {
            return (
              <>
                <CartRow cartProduct={product} key={product.id} />
              </>
            );
          })}
        </table>
      </div>
      <div className="mt-6 flex flex-col gap-y-3">
        <h1 className="text-center text-lg">Total: Rs. {cart.cart_total}</h1>
        <button
          className="p-3 bg-[#79C376] rounded-md w-full border border-black"
          onClick={handleCheckout}
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

export default Cart;
