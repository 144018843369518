import KhaltiCheckout from "khalti-checkout-web";

class KhaltiRoutes {
  static base_rotue = "https://khalti.com/api/v2/";
  static intiate_payment_route = this.base_rotue + "epayment/initiate/";
}

const get_khalti_config = (
  product_id,
  product_name,
  product_url,
  on_success,
  on_error
) => {
  return {
    publicKey: process.env.REACT_APP_KHALTI_PUBLIC_KEY,
    productIdentity: product_id,
    productName: product_name,
    productUrl: product_url,
    eventHandler: {
      onSuccess(payload) {
        on_success(payload);
      },
      onError(error) {
        on_error(error);
      },
    },
    paymentPreference: [
      "MOBILE_BANKING",
      "KHALTI",
      "EBANKING",
      "CONNECT_IPS",
      "SCT",
    ],
  };
};

export default {
  KhaltiRoutes,
  get_khalti_config,
};
