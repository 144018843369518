import { useContext, useEffect, useState } from "react";
import api from "../../common/hooks/api";
import helpers from "../helpers";
import ProductServices from "../../Product/services";
import Responses from "../../common/Responses";
import { toast } from "react-toastify";
import cartContext from "../State/CartContext";
import ClientRoutes from "../../common/ClientRoutes";
import { Link } from "react-router-dom";

const OrderDetailRow = ({ cartProduct }) => {
    useEffect(() => {
        console.log(cartProduct)
    }, [cartProduct]);
  if (cartProduct === undefined) return null;
  return (
    <tr className="text-center my-6 border border-black">
      <td  className="w-[5rem] overflow-hidden">
        <Link to={ClientRoutes.ProductRoutes.product_detail_route(cartProduct.product.id)} className="flex flex-col md:flex-row items-center justify-center">
          <div className="w-24 h-24 p-2">
            <img
              src={ProductServices.generate_media_url(
                cartProduct.product.images[0].image
              )}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-center md:text-start">
            <h1 className="text-xl">{cartProduct.product.title}</h1>
            <p>{cartProduct.product.quantity} gm.</p>
          </span>
        </Link>
      </td>
      <td className="w-14">
        <div className="flex justify-center items-center gap-x-3 w-full">
          <p className="text-xl w-[2rem]">{cartProduct.quantity}</p>
        </div>
      </td>
      <td className="w-14">
        <p>Rs. {cartProduct.product.price}</p>
      </td>
      <td className="w-14">
        <p>Rs.{cartProduct.product.price * cartProduct.quantity}</p>
      </td>

    </tr>
  );
};

export default OrderDetailRow;
