class Methods{
    static GET = "GET";
    static POST = "POST";
    static PUT = "PUT";
    static PATCH = "PATCH";
    static DELETE = "DELETE";
}

export default {
    Methods
};