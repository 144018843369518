import { useEffect, useState } from "react";
import CartContext from "./CartContext";
import useRequest from "../../common/hooks/useRequest";
import ServerRoutes from "../../common/ServerRoutes";
import Requests from "../../common/Requests";
import axios from "axios";

const CartProvider = (props) => {
  const [cart, setCart] = useState({});
  const [cart_products, setCartProducts] = useState([]);
  const [cart_total, setCartTotal] = useState(0);
  const [refresh_value, setRefreshValue] = useState(false); // This is a dummy variable to force the component to re-render when the cart is updated.
  const [isLogged, setIsLogged] = useState(false);

  const cart_response = useRequest(
    ServerRoutes.OrderRoutes.base_route,
    "GET",
    null,
    true,
    false
  );

  const fetch_cart = () => {
    axios.get(ServerRoutes.OrderRoutes.base_route, {
      headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
    })
    .then(res=>{
      setCart(res.data.data);
      setCartProducts(res.data.data.products);
    })
  };

  useEffect(() => {
    console.log("refreshing");
    setRefreshValue(false);
  }, [refresh_value]);

  const refreshState = () => {
    console.log(refresh_value);
    setRefreshValue(!refresh_value);
    fetch_cart();
  };

  useEffect(() => {
    if (cart_response.status === 200) {
      setCartProducts(cart_response.data.data.products);
      setIsLogged(true);
    } else setIsLogged(false);
  }, [cart_response, refresh_value]);

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        cart_products,
        setCartProducts,
        cart_total,
        setCartTotal,
        refreshState,
        isLogged,
        setIsLogged,
        setRefreshValue,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
