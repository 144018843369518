import axios from "axios";
import { useEffect, useState } from "react";
import ServerRoutes from "../../common/ServerRoutes";
import api from "../../common/hooks/api";
import OrderRow from "../Components/OrderRow";

const Order = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    api
      .get(ServerRoutes.OrderRoutes.order_route, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        //   if (response.data === undefined) return;
        setOrders(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="p-8 mt-24 pb-[12rem]">
      <h1 className="text-3xl text-center">Orders</h1>
      {orders.length === 0 ? (
        <h1 className="text-center text-2xl mt-8">No Orders</h1>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 mt-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3 text-center">
                Order Number
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Order Date
              </th>

              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              return <OrderRow order={order} key={order.id} />;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Order;
