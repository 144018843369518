import React, { useRef, useState } from "react";
import lunibah from "../static/videos/lunibah.mp4";
import dalle from "../static/videos/dalle.mp4";
import ghandruk from "../static/videos/ghandruk.mp4";


const Contents = () => {
  return (
    <>
    <h1 className="text-center text-3xl font-semibold pb-8 ">Content</h1>
      <div className="flex flex-col xl:flex-row lg:justify-around items-center gap-y-8 p-4 md:p-0">
        <VideoCard videoUrl={dalle} />
        <VideoCard videoUrl={lunibah} />
        <VideoCard videoUrl={ghandruk} />
      </div>
    </>
  );
};

const VideoCard = ({ videoUrl }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative md:w-[28rem] md:h-[39rem] overflow-hidden">
      <div className=" bg-gray-300 rounded-md overflow-hidden">
        <video
          src={videoUrl}
          className="object-cover w-full h-full"
          muted
          paused={true}
          autoPlay
          loop
          playsInline
          onClick={openPopup}
        ></video>
        <button
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
          onClick={openPopup}
        >
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-12 h-12"
          >
            <polygon points="5 3 19 12 5 21 5 3"></polygon>
          </svg> */}
        </button>
      </div>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-[95]">
          <div
            className="fixed bg-black bg-opacity-75 w-full h-full"
            onClick={closePopup}
          ></div>
          <div className="w-3/4 md:h-3/4 h-1/2 bg-white rounded-lg z-[99]">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closePopup}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <div className=" w-full h-full z-[99]">
              <iframe
                title="Video Player"
                className="w-full h-full z-[99]"
                src={videoUrl}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Contents;
