class Routes{
    static home_route = '/';
    static user_route = '/user';
    static category_route = '/category';
    static product_route = '/product';
    static cart_route = '/cart';
    static order_route = '/order';
    static NOT_FOUND_404 = '/404_Not_Found';
}

class UserRoutes extends Routes{
    static base_route = this.user_route;
    static login_route = this.user_route + '/login';
    static register_route = this.user_route + '/register';
}

class ProductRoutes extends Routes{
    static base_route = this.product_route;
    static product_detail_route = (product_id)=>{
        return this.product_route + `/${product_id}`;
    }
}

class CategoryRoutes extends Routes{
    static base_route = this.category_route;
    static category_detail_route = (category_id)=>{
        return this.category_route + `/${category_id}`;
    }
}

class CartRoutes extends Routes{
    static base_route = this.cart_route;

}

class OrderRoutes extends Routes{
    static base_route = this.order_route;
    static order_detail_route = (cart_id)=>{
        return this.base_route + `/${cart_id}`;
    }
}

class ErrorRoutes extends Routes{
    static base_route = this.home_route + '/404_not_found';
}


export default {
    Routes,
    UserRoutes,
    ProductRoutes,
    CategoryRoutes,
    CartRoutes,
    ErrorRoutes,
    OrderRoutes
};