import { useEffect, useState } from "react";
import CategoryCard from "../Components/CategoryCard";
import bg from "../static/Images/homepage_bg.png";
import api from "../../common/hooks/api";
import ServerRoutes from "../../common/ServerRoutes";
import Contents from "../Components/Contents";
import Footer from "../Components/Footer";

const Homepage = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    api
      .get(ServerRoutes.CategoryRoutes.base_route)
      .then((res) => {
        // console.log(res.data.data[0].image);
        setCategories(res.data.data);
      })
      .catch((e) => {
        return;
      });
  }, []);

  const desc =
    "<p>Where homemade goodness meets culinary excellence! At Kritiz Kitchen, we are passionate about crafting exceptional homemade achars that add a burst of flavor to your meals and elevate your dining experience. Our mission is simple: to bring the authentic taste of homemade achars to your table. We believe in using the finest ingredients sourced from trusted suppliers and combining them with time-honored recipes and techniques passed down through generations.</p>\
  <p>Each jar of Kritiz Kitchen achar is a labor of love. We pour our heart and soul into every batch, carefully selecting and preparing each ingredient to ensure the perfect balance of flavors. From tangy and spicy to savory and sweet, our diverse range of achars caters to various tastes and preferences. Experience the passion and craftsmanship of <strong>Kritiz Kitchen</strong>. Allow our homemade achars to enhance your meals, awaken your taste buds, and bring joy to your dining table.</p>";

  return (
    <>
      <div className="h-screen md:h-[60vh] mt-12 relative">
        <img
          src={bg}
          alt=""
          className="w-full h-full md:h-full object-cover z-[-1]"
        />
        <div
          className="absolute top-0 left-0 bg-black 
          w-full h-full opacity-60 z-[30]
          flex justify-center items-center flex-col text-center
          gap-y-4 p-8 md:p-12
          "
        >
          <h1 className="text-white text-3xl font-semibold item-center">
            {"Kritiz Kitchen"}
          </h1>
          <p
            className="text-white lg:max-w-[75%]"
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          ></p>
        </div>
      </div>
      <div className="my-4">
        <h1 className=" mb-8 text-center text-3xl">Categories</h1>
        <div className="flex flex-col xl:flex-row lg:justify-around items-center gap-y-8 p-4 md:p-0">
          {categories.map((category) => {
            return <CategoryCard category={category} key={category.id} />;
          })}
        </div>
      </div>

      <div className="bg-gray-200 px-4 pt-8 pb-[12rem] mt-16">
        <Contents />
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
