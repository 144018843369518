class BaseRoute{
    // static base_route = process.env.REACT_APP_SERVER_BASE_URL;
    static base_route = "https://api.kritizkitchen.com/"
    static media_route = this.base_route + 'media/';
    static store_rotue = this.base_route + 'store/'
}

class Routes extends BaseRoute{
    static user_route = this.base_route + 'user/';
    static category_route = this.store_rotue + 'category/';
    static product_route = this.store_rotue + 'product/';
    static cart_routes = this.store_rotue + 'cart/';
    static order_routes = this.store_rotue + 'order/';
    static payment_routes = this.store_rotue + 'payment/';
}

class UserRoutes extends Routes{
    static base_route = this.user_route;
    static login_route = this.user_route + 'login/';
    static register_route = this.user_route + 'register/';
    static refresh_route = this.user_route + 'refresh/';
}

class ProductRoutes extends Routes{
    static base_route = this.product_route;
    static product_detail_route = (product_id)=>{
        return this.product_route + `${product_id}`;
    }
}

class CategoryRoutes extends Routes{
    static base_route = this.category_route;
    static products_by_category_route = (category_id)=>{
        return this.category_route + `${category_id}`;
    }
}

class OrderRoutes extends Routes{
    static base_route = this.cart_routes;
    // static add_to_cart_route = base_route + '';
    static cart_detail_route = (cart_id)=>{
        return this.base_route + `${cart_id}/`;
    }
    static order_route = this.order_routes;
    static order_detail_route = (order_id)=>{
        return this.order_route + `${order_id}`;
    }
    
}

class PaymentRoutes extends Routes{
    static base_route = this.payment_routes;
    static payment_initiate_route = this.base_route + 'initiate/';
    static direct_order_route = this.base_route + 'order/direct';
    static cart_order_route = this.base_route + 'order/cart';
}


export default {
    BaseRoute,
    UserRoutes,
    ProductRoutes,
    CategoryRoutes,
    OrderRoutes,
    PaymentRoutes
};
