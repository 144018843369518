import { Link } from "react-router-dom";
import ServerRoutes from "../../common/ServerRoutes";
import services from "../../Product/services";
import { useEffect } from "react";
import ClientRoutes from "../../common/ClientRoutes";

const CategoryCard = ({category}) => {
    // useEffect(()=>{
    //     console.log(ServerRoutes.BaseRoute.base_route + category.image.substring(1))
    // })
  return (
      <div className="w-full md:w-[28rem] h-[32rem] bg-gray-200 group flex items-center justify-center cursor-pointer relative">
        <img
          src={ServerRoutes.BaseRoute.base_route + category.image.substring(1)}
          alt=""
          className="w-full h-full object-cover "
        />
        <Link
            to={ClientRoutes.CategoryRoutes.category_detail_route(category.id)}
            className="w-full h-full bg-black opacity-60 md:opacity-0 md:group-hover:opacity-60 duration-200 absolute left-0 top-0 z-[20] flex items-center justify-center">
            <h1 className="text-white text-3xl">{category.title}</h1>
        </Link>
      </div>
  );
};

export default CategoryCard;
