class ResponseCode{
    static SUCCESS_200 = 200;
    static SUCCESS_Created_201 = 201;
    static BAD_REQUEST_400 = 400;
    static UNAUTHORIZED_401 = 401;
    static NOT_FOUND_404 = 404;
    static INTERNAL_SERVER_ERROR_500 = 500;
}



export default {
    ResponseCode,
};