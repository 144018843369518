import { useContext, useState } from "react";
import api from "../../common/hooks/api";
import helpers from "../helpers";
import ProductServices from "../../Product/services";
import Responses from "../../common/Responses";
import { toast } from "react-toastify";
import cartContext from "../State/CartContext";
import { Link } from "react-router-dom";
import ClientRoutes from "../../common/ClientRoutes";

const OrderRow = ({ order }) => {
  return (
    <tr className="bg-white border-b ">
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center"
      >
        {order.id}
      </th>
      <td className="px-6 py-4 text-center">
        {order.ordered_at
          ?
          new Date(order.ordered_at).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          : null}
      </td>
      <td className="px-6 py-4">
        <Link
        to={ClientRoutes.OrderRoutes.order_detail_route(order.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill=""
            className="bi bi-eye-fill"
            viewBox="0 0 16 16"
          >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
          </svg>
        </Link>
      </td>
    </tr>
  );
};

export default OrderRow;
