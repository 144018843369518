import axios from "axios";
import server_routes from "../common/ServerRoutes";

async function login(username, password) {
  try {
    const response = await axios.post(server_routes.UserRoutes.login_route, {
      username: username,
      password: password,
    });
    return { data: response.data, status: response.status };
  } catch (error) {
    // console.log(error.response.data.detail);
    throw new Error(error.response.data.detail);
  }
}

async function register({
  firstname,
  lastname,
  username,
  email,
  password,
  cfm_password,
}) {
    const response = await axios.post(server_routes.UserRoutes.register_route, {
      first_name: firstname,
      last_name: lastname,
      username: username,
      email: email,
      password: password,
      cfm_password: cfm_password,
    });
    return { data: response.data, status: response.status };

}

export default {
  login,
  register,
};
