import ImageCarousel from "../Components/ImageCarousel";
import useRequest from "../../common/hooks/useRequest";
import ServerRoutes from "../../common/ServerRoutes";
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Request from "../../common/Requests";
import Responses from "../../common/Responses";
import services from "../services";
import ClientRoutes from "../../common/ClientRoutes";
import { toast } from "react-toastify";
import CartHelper from "../../Cart/helpers";
import cartContext from "../../Cart/State/CartContext";
import Footer from "../../App/Components/Footer";
import axios from "axios";
import api from "../../common/hooks/api";
import helpers from "../../Cart/helpers";

const Product = () => {
  const [images, setImages] = useState([]);

  const { product_id } = useParams();
  const [product, setProduct] = useState({ data: null });
  const [quantity, setQuantity] = useState(1);
  const { isLogged, setIsLogged, setRefreshValue, refreshState } =
    useContext(cartContext);

  const product_response = useRequest(
    ServerRoutes.ProductRoutes.product_detail_route(product_id),
    Request.Methods.GET
  );

  const cart_response = useRequest(
    ServerRoutes.OrderRoutes.base_route,
    Request.Methods.GET,
    null,
    true,
    false
  );

  const handleAddToCart = () => {
    // console.log(localStorage.)

    services.add_product_to_cart(product_id, quantity).then((res) => {
      if (res.status === Responses.ResponseCode.SUCCESS_200) {
        toast.success("Product added to cart.");
        refreshState();
      }
    });
  };

  const handleCheckout = () => {
    if (isLogged === false) {
      window.location.href = ClientRoutes.UserRoutes.login_route;
      return;
    }
    handleOrderNow("initiate");
    // const checkout = CartHelper.khalti_V1_checkout(
    //   product_id,
    //   product.title.toString(),
    //   "https://kritizkitchen.com/cart",
    //   (data) => {
    //     handleOrderNow();
    //   },
    //   (error) => {
    //     toast.error("Payment failed.");
    //   }
    // );
    // checkout.show({ amount: product.price * quantity * 100 });

    // axios.post(
    //   ServerRoutes.PaymentRoutes.payment_initiate_route,
    //   {
    //     product: product_id,
    //     quantity: quantity
    //   }
    // )
    // .then((response) => {
    //   window.location.href = response.data.payment_url;
    // })
  };

  const handleOrderNow = (event = "initiate", token = null) => {
    helpers
      .khalti_V2_checkout({
        return_url: ServerRoutes.PaymentRoutes.direct_order_route,
        price: product.price * quantity * 100,
        purchase_order_id: `${product.id}/${quantity}`,
        purchase_order_name: product.title,
        access_token: localStorage.getItem("access")
      });
    // if (event === "initiate") {
    //   axios
    //     .post(ServerRoutes.PaymentRoutes.payment_initiate_route, {
    //       return_url: window.location.href,
    //       price: product.price * 100,
    //       purchase_order_id: product.id,
    //       purchase_order_name: product.title,
    //     })
    //     .then((response) => {
    //       // console.log(window.location.href);
    //       if (response.status === 200 && response.data.payment_url) {
    //         window.location.href = response.data.payment_url;
    //       }
    //     });
    // } else if (event === "purchase" && token) {
    //   services
    //     .order_now({
    //       product_id,
    //       quantity,
    //       token
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       // window.location.href = ClientRoutes.OrderRoutes.order_detail_route(
    //       //   res.data.data.id
    //       // );
    //     })
    //     .catch((e) => {
    //       toast.error("Server Error.");
    //     });
    // } else {
    //   throw new Error("Invalid order event.");
    // }
  };

  const change_quantity = (quantity) => {
    setQuantity(quantity);
  };

  useEffect(() => {
    if (
      product_response.status !== undefined &&
      product_response.status !== Responses.ResponseCode.SUCCESS_200
    )
      window.location.href = ClientRoutes.Routes.NOT_FOUND_404;

    if (product_response.data !== undefined) {
      setProduct(product_response.data.data);
      setImages(product_response.data.data.images);
    }

    if (cart_response.status === Responses.ResponseCode.SUCCESS_200) {
      const data = cart_response.data;
      if (data !== undefined) {
        const cart = data.data;
        const products = cart.products;
        for (let i = 0; i < products.length; i++) {
          if (products[i].product.id === parseInt(product_id)) {
            setQuantity(products[i].quantity);
            break;
          }
        }
      }
    }
  }, [product_response, cart_response]);

  return (
    <div className="container mx-auto flex flex-col lg:flex-row mt-32 pb-[12rem] lg:pb-8 gap-x-12">
      <div className="lg:w-1/2 p-4 md:p-0 overflow-hidden">
        <ImageCarousel images={images} />
        <div>{/* Other product details */}</div>
      </div>
      <div className="py-6 lg:w-1/2 flex flex-col gap-y-0 p-4 md:p-0">
        <div className="flex flex-col gap-y-1 text-center lg:text-justify">
          <h1 className="text-3xl font-semibold">{product.title}</h1>
          <p>{product.quantity}gm.</p>
          <h1 className="font-bold text-lg">Rs. {product.price}</h1>
        </div>
        <p
          className="text-center lg:text-justify"
          dangerouslySetInnerHTML={{ __html: product.description }}
        >
          {/* {product.description} */}
        </p>
        <div className="flex items-center justify-center mt-3 lg:justify-start lg:gap-y-8 w-full lg:w-44 gap-x-3">
          <h1>Quantity</h1>
          <span className="flex items-center gap-x-3">
            <p
              className="cursor-pointer"
              onClick={() => {
                change_quantity(quantity - 1);
              }}
            >
              -
            </p>
            <h1>{quantity}</h1>{" "}
            <p
              className="cursor-pointer"
              onClick={() => {
                change_quantity(quantity + 1);
              }}
            >
              +
            </p>
          </span>
        </div>
        <div className="flex flex-col md:flex-row gap-x-4">
          <button
            className="flex items-center justify-center w-full lg:w-44 gap-x-3 border
        border-black hover:bg-black hover:text-white group duration-150 px-4 py-4 mt-3 "
            onClick={handleAddToCart}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              // fill="currentColor"
              className="bi bi-cart-dash group-hover:fill-white duration-150"
              viewBox="0 0 16 16"
            >
              <path d="M6.5 7a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4z" />
              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
            Add to Cart
          </button>

          <button
            className="flex items-center justify-center w-full lg:w-44 gap-x-3 border
        border-black hover:bg-black hover:text-white group duration-150 px-4 py-4 mt-3 "
            onClick={handleCheckout}
          >
            Order Now
          </button>
        </div>
      </div>
      <div className="lg:hidden absolute left-0 bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Product;
