import api from "../common/hooks/api";
import Responses from "../common/Responses";
import ServerRoutes from "../common/ServerRoutes";
import { toast } from "react-toastify";
const add_product_to_cart = async (product_id, quantity = null) => {
  try {
    const response = await api.post(
      ServerRoutes.OrderRoutes.base_route,
      {
        product: product_id,
        quantity: quantity,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    return response;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

const fetch_cart = async (access) => {
  try {
    const response = await api.get(ServerRoutes.OrderRoutes.base_route, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });
    return response;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

const order_now = async ({ product_id, quantity = 1, token = null }) => {
  try {
    const response = await api.post(
      ServerRoutes.OrderRoutes.order_route,
      {
        product: product_id,
        quantity: quantity,
        token: token
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        },
      }
    );
    return response;
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

const generate_media_url = (url) => {
  return ServerRoutes.BaseRoute.media_route + url;
};

export default {
  add_product_to_cart,
  fetch_cart,
  generate_media_url,
  order_now,
};
