const NotFound = () => {
  return (
    <h1
      className="w-full h-screen
        flex items-center justify-center 
        text-3xl"
    >
      404 Not Found
    </h1>
  );
};

export default NotFound;
