import useRequest from "../../common/hooks/useRequest";
import client_routes from "../../common/ClientRoutes";
import server_routes from "../../common/ServerRoutes";
import { useState, useEffect } from "react";
import service from "../services.js";
import bg from "../../Product/static/images/categroy-img.png";
import { toast } from "react-toastify";
import ClientRoutes from "../../common/ClientRoutes";
import { Link } from "react-router-dom";
const Signup = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm() === false) {
      return;
    }
    try {
      const res = await service.register({
        username: username,
        password: password,
        firstname: firstname,
        lastname: lastname,
        email: email,
        cfm_password: confirm_password,
      });
      if (res.status == 201) {
        window.location.href = client_routes.UserRoutes.login_route;
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const validateForm = () => {
    if (username.length < 4) {
      toast.error("Username must be atleast 4 characters long.");
      return false;
    }
    if (password !== confirm_password) {
      toast.error("Passwords do not match.");
      return false;
    }
    if (firstname.length < 4 || lastname.length < 2) {
      toast.error(
        "First name and last name must be atleast 4 characters long."
      );
      return false;
    }
    if (email.length < 1) {
      toast.error("Please enter a valid email.");
      return false;
    }
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters long.");
      return false;
    }
    return true;
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="p-12  w-full lg:w-1/2">
        <h1 className="text-center text-3xl font-semibold py-8">Register</h1>
        <form
          onSubmit={handleSubmit}
          action=""
          className="flex flex-col gap-y-4"
        >
          <input
            type="text"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="First Name"
          />
          <input
            type="text"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="Last Name"
          />
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="Username"
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="E-mail"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="Password"
          />
          <input
            type="password"
            value={confirm_password}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="Confirm Password"
          />

          <input
            type="submit"
            value={"Register"}
            className="p-3 border border-black bg-black text-white cursor-pointer rounded-md hover:text-black hover:bg-white"
          />
          <Link to={ClientRoutes.UserRoutes.login_route} className="text-blue-700">
            Already have an account?
          </Link>
        </form>
      </div>
      <div className="lg:w-[45%] h-[60%] hidden lg:inline relative">
        <img src={bg} alt="" className="w-full h-full object-cover" />
        <div className="bg-black z-60 w-full h-full absolute left-0 top-0 opacity-60 flex items-center justify-center">
          <h1 className="text-white text-3xl opacity-100">Kritiz Kitchen</h1>
        </div>
      </div>
    </div>
  );
};

export default Signup;
