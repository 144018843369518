import { useContext, useState } from "react";
import api from "../../common/hooks/api";
import helpers from "../helpers";
import ProductServices from "../../Product/services";
import Responses from "../../common/Responses";
import { toast } from "react-toastify";
import cartContext from "../State/CartContext";
import { Link } from "react-router-dom";
import ClientRoutes from "../../common/ClientRoutes";

const CartRow = ({ cartProduct }) => {
  const cart = useContext(cartContext);

  const handleIncrement = () => {
    ProductServices.add_product_to_cart(
      cartProduct.product.id,
      cartProduct.quantity + 1
    ).then(() => {
      ProductServices.fetch_cart(localStorage.getItem("access"))
        .then((response) => {
          cart.setCart(response.data.data);
          cart.setCartProducts(response.data.data.products);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleDecrement = () => {
    ProductServices.add_product_to_cart(
      cartProduct.product.id,
      cartProduct.quantity - 1
    ).then((res) => {
      ProductServices.fetch_cart(localStorage.getItem("access"))
        .then((response) => {
          cart.setCart(cart.cart_products.length === 0);
          cart.setCartProducts(response.data.data.products);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleDelete = () => {
    ProductServices.add_product_to_cart(cartProduct.product.id, 0).then(() => {
      ProductServices.fetch_cart(localStorage.getItem("access"))
        .then((response) => {
          cart.setCart(response.data.data);
          cart.setCartProducts(response.data.data.products);
          cart.setCartTotal(0);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  return (
    <tr
      className="text-center my-6 border border-black w-full"
      key={cartProduct.id}
    >
      <td className="w-[20rem]">
        <Link to={ClientRoutes.ProductRoutes.product_detail_route(cartProduct.product.id)} className="flex flex-row items-center justify-center gap-x-6">
          <div className="w-24 h-24 p-2">
            <img
              src={ProductServices.generate_media_url(
                cartProduct.product.images[0].image
              )}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <span className="text-start w-36">
            <h1 className="text-xl">{cartProduct.product.title}</h1>
            <p>{cartProduct.product.quantity} gm.</p>
          </span>
        </Link>
      </td>
      <td>
        <div className="flex justify-center items-center gap-x-3 w-full">
          <p className="text-2xl cursor-pointer" onClick={handleDecrement}>
            {" "}
            -{" "}
          </p>
          <p className="text-xl w-[2rem]">{cartProduct.quantity}</p>
          <p className="text-2xl cursor-pointer" onClick={handleIncrement}>
            {" "}
            +{" "}
          </p>
        </div>
      </td>
      <td>
        <p>Rs. {cartProduct.product.price}</p>
      </td>
      <td>
        <p>Rs.{cartProduct.product.price * cartProduct.quantity}</p>
      </td>
      <td>
        <button onClick={handleDelete}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            className="bi bi-trash3-fill fill-red-600 mt-2"
            viewBox="0 0 16 16"
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </button>
      </td>

      <td></td>
    </tr>
  );
};

export default CartRow;
