import { useEffect, useState } from "react";
import api from "../../common/hooks/api";
import ServerRoutes from "../../common/ServerRoutes";
import { useParams } from "react-router-dom";
import axios from "axios";
import useRequest from "../../common/hooks/useRequest";
import Requests from "../../common/Requests";
import OrderRow from "../Components/OrderRow";
import OrderDetailRow from "../Components/OrderDetailRow";

const OrderDetail = () => {
  const { order_id } = useParams();
  const [order, setOrder] = useState({});
  const [total, setTotal] = useState(0);
  const orderDetail = useRequest(
    ServerRoutes.OrderRoutes.order_detail_route(order_id),
    Requests.Methods.GET,
    null,
    true,
    true
  );

  useEffect(() => {
    if (orderDetail.data !== undefined) {
      setOrder(orderDetail.data.data);
    }
  }, [orderDetail, order]);
  useEffect(() => {
    if (order.products === undefined) return;
    for (let i = 0; i < order.products.length; i++) {
      setTotal(
        total + order.products[i].product.price * order.products[i].quantity
      );
    }
  }, [orderDetail, order]);

  return (
    <>
      <div className="p-8 mt-24">
        <h1 className="text-3xl text-center">Order Details</h1>
        <p className="text-center">
          {new Date(order.ordered_at).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
        <h1 className="text-center text-xl font-semibold mt-2">
          Total: {total}
        </h1>
        <div className="max-w-screen overflow-auto p-1">
          <table className="sm:w-full mt-8">
            <tr className="text-xl gap-8">
              <th className="px-8">Product</th>
              <th className="px-8">Qty</th>
              <th className="px-8">Price</th>
              <th className="px-8">Total</th>
            </tr>
            {order.products !== undefined &&
              order.products.map((product) => {
                return (
                  <OrderDetailRow cartProduct={product} key={product.id} />
                );
              })}
          </table>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
