import useRequest from "../../common/hooks/useRequest";
import client_routes from "../../common/ClientRoutes";
import server_routes from "../../common/ServerRoutes";
import { useState, useEffect } from "react";
import service from "../services.js";
import bg from "../../Product/static/images/categroy-img.png";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ClientRoutes from "../../common/ClientRoutes";
const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill in the credentials.");
      return;
    }
    try {
      const res = await service.login(username, password);
      if (res.status == 200) {
        console.log(res.data);
        localStorage.setItem("access", res.data.access);
        localStorage.setItem("refresh", res.data.refresh);
        window.location.href = client_routes.Routes.home_route;
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const validateForm = () => {
    if (username.length < 4 || password.length < 4) return false;
    return true;
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="p-12  w-full lg:w-1/2">
        <h1 className="text-center text-3xl font-semibold py-8">Login</h1>
        <form
          onSubmit={handleSubmit}
          action=""
          className="flex flex-col gap-y-4"
        >
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="Username"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-3 border border-black rounded-md"
            placeholder="Password"
          />

          <input
            type="submit"
            value={"Login"}
            className="p-3 border border-black bg-black text-white cursor-pointer rounded-md hover:text-black hover:bg-white"
          />
          <Link to={ClientRoutes.UserRoutes.register_route} className="text-blue-700">Don't have an account?</Link>
        </form>
      </div>
      <div className="lg:w-[45%] h-[60%] hidden lg:inline relative">
        <img src={bg} alt="" className="w-full h-full object-cover" />
        <div className="bg-black z-60 w-full h-full absolute left-0 top-0 opacity-60 flex items-center justify-center">
          <h1 className="text-white text-3xl opacity-100">Kritiz Kitchen</h1>
        </div>
      </div>
    </div>
  );
};

export default Login;
