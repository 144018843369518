import React, { useContext, useEffect, useState } from "react";
import logo from "../static/Images/Kritiz_logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import ServerRoutes from "../../common/ServerRoutes";
import ClientRoutes from "../../common/ClientRoutes";
import useRequest from "../../common/hooks/useRequest";
import cartContext from "../../Cart/State/CartContext";
const Navbar = () => {
  const [categories, setCategories] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios
      .get(ServerRoutes.CategoryRoutes.base_route)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((e) => {
        console.log(`error: ${e.message}`);
      });
  }, []);

  const { isLogged, setIsLogged, cart_products } = useContext(cartContext);

  return (
    <nav className=" border-gray-200 bg-[#FCF6E7] z-[94] fixed w-full top-0">
      <div className=" flex flex-wrap items-center justify-between w-full py-4 px-8 md:px-24">
        <Link to="/" className="flex items-center">
          <img src={logo} className="h-14 mr-3" alt="logo" />
        </Link>
        <button
          data-collapse-toggle="navbar-dropdown"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200  "
          aria-controls="navbar-dropdown"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-dropdown">
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 ">
            <li>
              <button
                id="dropdownNavbarLink"
                data-dropdown-toggle="dropdownNavbar"
                className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto"
              >
                Categories{" "}
                <svg
                  className="w-5 h-5 ml-1"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>

              <div
                id="dropdownNavbar"
                className="z-[90] hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-full md:w-44"
              >
                <ul
                  className="py-2 text-sm text-gray-700 "
                  aria-labelledby="dropdownLargeButton"
                >
                  {categories.map((category) => {
                    return (
                      <li key={category.id}>
                        <Link
                          to={ClientRoutes.CategoryRoutes.category_detail_route(
                            category.id
                          )}
                          className="block px-4 py-2 "
                        >
                          {category.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li>

            {isLogged ? LoggedInList() : unLoggedInList()}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const unLoggedInList = () => {
  return (
    <>
      <li>
        <Link
          to={ClientRoutes.UserRoutes.login_route}
          className="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
        >
          Login
        </Link>
      </li>

      <li>
        <Link
          to={ClientRoutes.UserRoutes.register_route}
          className="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
        >
          Signup
        </Link>
      </li>
    </>
  );
};

const LoggedInList = () => {
  const { setLogged, cart_products } = useContext(cartContext);

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    setLogged(false);
    window.location.href = ClientRoutes.Routes.home_route;
  };

  return (
    <>
      <li className="relative">
        <Link
          to={ClientRoutes.CartRoutes.base_route}
          className="flex items-center justify-between py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
        >
          Cart{" "}
          {cart_products.length ? (
            <p className="md:absolute md:top-[-10px] md:right-[-18px] w-5 h-5 text-center flex items-center justify-center z-[-1] bg-red-600 text-white text-[12px] rounded-full">
              {cart_products.length}
            </p>
          ) : null}
        </Link>
      </li>
      <li>
        <Link
          to={ClientRoutes.OrderRoutes.base_route}
          className="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
        >
          Orders
        </Link>
      </li>
      <li>
        <Link
          to={ClientRoutes.Routes.home_route}
          onClick={handleLogout}
          className="block py-2 pl-3 pr-4 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
        >
          Logout
        </Link>
      </li>
    </>
  );
};

export default Navbar;
