import React, { useEffect, useState } from "react";
import server_routes from "../../common/ServerRoutes";
import services from "../services";
const ImageCarousel = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image) => {
    // setSelectedImage(image);
    setSelectedImage(services.generate_media_url(image));
  };

  useEffect(() => {
    if (images.length > 0)
      setSelectedImage(services.generate_media_url(images[0].image));
  }, [images]);

  return (
    <div className="px-4">
      <div className="w-full h-[32rem]">
        {images.length > 0 ? (
          <img
            src={selectedImage}
            alt="Big Image"
            className="w-full h-full object-cover"
          />
        ) : null}
      </div>
      <div className="flex justify-center lg:justify-start mt-4 overflow-x-auto">
        {images.map((image, index) => (
          <>
            <img
              key={index}
              src={`${services.generate_media_url(image.image)}`}
              alt={`Small Image ${index + 1}`}
              // mx-2 only for all elements except the first one.
              className={`w-32 h-32 rounded cursor-pointer object-cover mx-2`}
              onClick={() => handleImageClick(image.image)}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
