import axios from "axios";
import { useState, useEffect } from "react";
import client_routes from "../ClientRoutes";
import server_routes from "../ServerRoutes";
import responses from "../Responses";

const useRequest = (
  url,
  method,
  body=null,
  useAuth = false,
  redirect = true,
  redirect_route = client_routes.UserRoutes.login_route
) => {
  const methods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
  };

  const [response, setResponse] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      // Check if method is valid.
      method = method.toUpperCase();
      if (methods[method] === undefined)
        throw new Error("Invalid method passed to useAuth hook.");

      try {
        // Send request.
        const res = await axios.request({
          request: methods[method],
          url: url,
          method: methods[method],
          data: (body!=null && Object.keys(body).length!=0) ? body : null, // Check if body is required.
          headers: {
            Authorization: useAuth // Check if auth is required.
              ? `Bearer ${localStorage.getItem("access")}`
              : null,
          },
        });
        setResponse(res);
      } catch (error) {
        if (error.response.status === responses.ResponseCode.UNAUTHORIZED_401) {
          const refresh = localStorage.getItem("refresh"); // Refresh Token.
          if (refresh !== null) {
            const res = await axios.request({
              request: methods["POST"],
              url: server_routes.UserRoutes.refresh_route,
              method: methods["POST"],
              data: { refresh: refresh },
            });
            localStorage.setItem("access", res.data.access);
            localStorage.setItem("refresh", res.data.refresh);
            if (res.status === responses.ResponseCode.UNAUTHORIZED_401){
              // localStorage.removeItem("access");
              // localStorage.removeItem("refresh");
              window.location.href = client_routes.UserRoutes.login_route;
              return;
            }
          
            // setResponse(res);
            await fetchData();
          }

          if (redirect && refresh === null) {
            window.location.href = redirect_route;
          }
        }
        setResponse(error.response);
      }
    };

    fetchData();
  }, []);

  return response;
};

export default useRequest;
