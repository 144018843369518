import { Link } from "react-router-dom";
import client_routes from "../../common/ClientRoutes";
import services from "../../Product/services";
import { useContext, useEffect } from "react";
import ServerRoutes from "../../common/ServerRoutes";
import Responses from "../../common/Responses";
import { toast } from "react-toastify";
import cartContext from "../../Cart/State/CartContext";
const ProductCard = ({ product }) => {
  const { refreshState } = useContext(cartContext);
  const handle_add_to_cart = (e) => {
    e.preventDefault();
    services.add_product_to_cart(product.id).then((res) => {
      console.log(res.status);
      if (res.status === Responses.ResponseCode.SUCCESS_200) {
        toast.success("Product added to cart.");
        refreshState();
      }
    });
  };

  return (
    <Link
      to={client_routes.ProductRoutes.product_detail_route(product.id)}
      className="rounded p-4 shadow-lg w-[24rem] cursor-pointer mx-auto hover:scale-[1.01] duration-100"
    >
      <div className="w-full h-[18rem]">
        <img
          src={ServerRoutes.BaseRoute.media_route + product.images[0].image}
          alt="Product"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="pt-4 pb-2">
        <h1 className="font-bold text-xl ">{product.title}</h1>
        <p className="text-gray-700 text-base">{product.quantity}gm.</p>
      </div>
      <div className="flex justify-between items-end">
        <p className="text-lg">Rs.{product.price}</p>
        <button
          className="z-[20] flex items-center gap-x-3 border border-black hover:bg-black hover:text-white group duration-150 px-4 py-2"
          onClick={(e) => {
            handle_add_to_cart(e);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            // fill="currentColor"
            class="bi bi-cart-dash group-hover:fill-white duration-150"
            viewBox="0 0 16 16"
          >
            <path d="M6.5 7a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4z" />
            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
          Add to Cart
        </button>
      </div>
    </Link>
  );
};

export default ProductCard;
